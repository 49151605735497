import './App.css';
import {useEffect, useState} from "react";

const App = () => {
  const [accountingType, setAccountingType] = useState('simplified');
  const [documentCount, setDocumentCount] = useState('15'); // Default to '15' documents
  const [employeeCount, setEmployeeCount] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [price, setPrice] = useState(0);
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [lastResult, setLastResult] = useState(null);

  const handleAccountingTypeChange = (type) => {
    setAccountingType(type);
    const defaultDocumentCount = type === 'simplified' ? '15' : '15';
    setDocumentCount(defaultDocumentCount); // Set default document count
  };

  useEffect(() => {
    calculatePrice(documentCount, employeeCount);
  }, [accountingType, employeeCount, documentCount])

  const handleDocumentCountChange = (count) => {
    setDocumentCount(count);
  };

  const handleEmployeeCountChange = (count) => {
    setEmployeeCount(count);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!phoneNumber) newErrors.phoneNumber = 'Nieprawidłowy numer telefonu';
    if (!email) newErrors.email = 'Nieprawidłowy adres email';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const calculatePrice = (documents, employees) => {
    let basePrice = 0;
    let employeePrice = 0;

    if (accountingType === 'simplified') {
      switch (documents) {
        case '15':
          basePrice = 150;
          break;
        case '45':
          basePrice = 250;
          break;
        case '70':
          basePrice = 350;
          break;
        case '70+':
          basePrice = 'Wycena indywidualna';
          break;
        default:
          basePrice = 0;
      }
    } else {
      switch (documents) {
        case '15':
          basePrice = 400;
          break;
        case '45':
          basePrice = 550;
          break;
        case '60':
          basePrice = 700;
          break;
        case '85':
          basePrice = 850;
          break;
        case '85+':
          basePrice = 'Wycena indywidualna';
          break;
        default:
          basePrice = 0;
      }
    }

    if (employees > 0) {
      employeePrice = employees * 30; // Example price per employee
    }

    if (basePrice !== 'Wycena indywidualna') {
      setPrice(basePrice + employeePrice);
    } else {
      setPrice('Wycena indywidualna');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (validateForm()) {
      try {
        const response = await fetch('/mail.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            companyType: accountingType,
            documentsCount: documentCount,
            staffCount: employeeCount,
            phoneNumber,
            email,
          }),
        });
        const result = await response.json();
        setLastResult(result);
        console.log(result);
        // Handle the response from the server
      } catch (error) {
        console.error('Error submitting the form:', error);
      }
    }

    setSubmitted(false);
  };

  return (
      <div className="App md:p-12 bg-gray-100">
        <div className="app-container">
          <div className="flex flex-col-reverse md:flex-row mr-2">
            <div className="text-left w-full md:w-2/5">
              <div className="flex items-center mb-4">
                <div
                    className="min-w-8 mr-4 flex justify-center items-center bg-[#325F74] rounded-full w-8 h-8 text-white">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5"></path>
                  </svg>
                </div>
                <div><p className="text-lg font-semibold text-[#325F74]">Pełna i uproszczona księgowość</p><p>Pomożemy
                  Ci wybrać odpowiednią formę prowadzenia Twojej firmy.</p></div>
              </div>
              <div className="flex items-center mb-4">
                <div
                    className="min-w-8 mr-4 flex justify-center items-center bg-[#325F74] rounded-full w-8 h-8 text-white">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5"></path>
                  </svg>
                </div>
                <div><p className="text-lg font-semibold text-[#325F74]">Płace i kadry</p><p>Zapewniamy kompleksową
                  obsługę kadrowo-płacową Twoich pracowników.</p></div>
              </div>
              <div className="flex items-center mb-4">
                <div
                    className="min-w-8 mr-4 flex justify-center items-center bg-[#325F74] rounded-full w-8 h-8 text-white">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5"></path>
                  </svg>
                </div>
                <div><p className="text-lg font-semibold text-[#325F74]">Rozliczanie podatku zza granicy</p>
                  <p>Pracowałeś za granicą? Rozliczamy Klientów pracujących w Niemczech, Austrii, Anglii, Irlandii oraz
                    Holandii. Przeanalizujemy Twoją sytuację i rozliczymy Cię zgodnie z obowiązującymi przepisami w
                    Polsce oraz za granicą. Zapewniamy wsparcie i pomoc przez cały proces rozliczenia.</p></div>
              </div>
              <div className="flex items-center mb-4">
                <div
                    className="min-w-8 mr-4 flex sef-auto justify-center items-center bg-[#325F74] rounded-full w-8 h-8 text-white">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5"></path>
                  </svg>
                </div>
                <div><p className="text-lg font-semibold text-[#325F74]">Nowoczesne technologie w księgowości</p><p>Nasz
                  zespół doświadczonych księgowych i informatyków pracuje z najnowszymi technologiami, aby zapewnić Ci
                  szybkie, dokładne i profesjonalne usługi, z podglądem do bieżących rozliczeń i deklaracji. Obieg
                  dokumentów między Klientem i Biurem Rachunkowym może odbywać się tradycyjnie, papierowo, lub w pełni
                  elektronicznie.</p></div>
              </div>
              <div className="flex items-center mb-4">
                <div><p>Jeśli szukasz profesjonalnej i wiarygodnej firmy, która zajmie się Twoją rachunkowością, to
                  Biuro Rachunkowe PIMAG jest idealnym wyborem.</p></div>
              </div>
            </div>
            <div className="bg-white rounded-lg shadow-md w-full md:w-3/5 px-8 py-4 mb-8 ml-2">
              <form onSubmit={handleSubmit}>
                <div className="step mb-8">
                  <h2 className="text-xl mb-2">1. Rodzaj księgowości</h2>
                  <div className="flex flex-row justify-center">
                    <div
                        className={`rounded-md mr-2 px-2 py-1 cursor-pointer ${
                            accountingType === 'simplified'
                                ? 'bg-[#325F74] text-white'
                                : 'bg-white text-[#325F74] border-[#2B5366] border-2'
                        }`}
                        onClick={() => handleAccountingTypeChange('simplified')}
                    >
                      Księgowość uproszczona (KPiR, Ryczałt)
                    </div>
                    <div
                        className={`rounded-md ml-2 px-2 py-1 cursor-pointer ${
                            accountingType === 'full'
                                ? 'bg-[#325F74] text-white'
                                : 'bg-white text-[#325F74] border-[#2B5366] border-2'
                        }`}
                        onClick={() => handleAccountingTypeChange('full')}
                    >
                      Księgowość pełna (Księgi handlowe)
                    </div>
                  </div>
                </div>
                <div className="step mb-8">
                  <h2 className="text-xl mb-2">2. Ilość dokumentów</h2>
                  <div className="flex flex-row justify-center flex-wrap">
                    {accountingType === 'simplified' ? (
                        <>
                          <div
                              className={`rounded-md mr-2 mb-2 px-2 py-1 cursor-pointer ${
                                  documentCount === '15'
                                      ? 'bg-[#325F74] text-white'
                                      : 'bg-white text-[#325F74] border-[#2B5366] border-2'
                              }`}
                              onClick={() => handleDocumentCountChange('15')}
                          >
                            do 15 dokumentów
                          </div>
                          <div
                              className={`rounded-md mr-2 mb-2 px-2 py-1 cursor-pointer ${
                                  documentCount === '45'
                                      ? 'bg-[#325F74] text-white'
                                      : 'bg-white text-[#325F74] border-[#2B5366] border-2'
                              }`}
                              onClick={() => handleDocumentCountChange('45')}
                          >
                            do 45 dokumentów
                          </div>
                          <div
                              className={`rounded-md mr-2 mb-2 px-2 py-1 cursor-pointer ${
                                  documentCount === '70'
                                      ? 'bg-[#325F74] text-white'
                                      : 'bg-white text-[#325F74] border-[#2B5366] border-2'
                              }`}
                              onClick={() => handleDocumentCountChange('70')}
                          >
                            do 70 dokumentów
                          </div>
                          <div
                              className={`rounded-md mr-2 mb-2 px-2 py-1 cursor-pointer ${
                                  documentCount === '70+'
                                      ? 'bg-[#325F74] text-white'
                                      : 'bg-white text-[#325F74] border-[#2B5366] border-2'
                              }`}
                              onClick={() => handleDocumentCountChange('70+')}
                          >
                            powyżej 70 dokumentów
                          </div>
                        </>
                    ) : (
                        <>
                          <div
                              className={`rounded-md mr-2 mb-2 px-2 py-1 cursor-pointer ${
                                  documentCount === '15'
                                      ? 'bg-[#325F74] text-white'
                                      : 'bg-white text-[#325F74] border-[#2B5366] border-2'
                              }`}
                              onClick={() => handleDocumentCountChange('15')}
                          >
                            do 15 dokumentów
                          </div>
                          <div
                              className={`rounded-md mr-2 mb-2 px-2 py-1 cursor-pointer ${
                                  documentCount === '45'
                                      ? 'bg-[#325F74] text-white'
                                      : 'bg-white text-[#325F74] border-[#2B5366] border-2'
                              }`}
                              onClick={() => handleDocumentCountChange('45')}
                          >
                            do 45 dokumentów
                          </div>
                          <div
                              className={`rounded-md mr-2 mb-2 px-2 py-1 cursor-pointer ${
                                  documentCount === '60'
                                      ? 'bg-[#325F74] text-white'
                                      : 'bg-white text-[#325F74] border-[#2B5366] border-2'
                              }`}
                              onClick={() => handleDocumentCountChange('60')}
                          >
                            do 60 dokumentów
                          </div>
                          <div
                              className={`rounded-md mr-2 mb-2 px-2 py-1 cursor-pointer ${
                                  documentCount === '85'
                                      ? 'bg-[#325F74] text-white'
                                      : 'bg-white text-[#325F74] border-[#2B5366] border-2'
                              }`}
                              onClick={() => handleDocumentCountChange('85')}
                          >
                            do 85 dokumentów
                          </div>
                          <div
                              className={`rounded-md mr-2 mb-2 px-2 py-1 cursor-pointer ${
                                  documentCount === '85+'
                                      ? 'bg-[#325F74] text-white'
                                      : 'bg-white text-[#325F74] border-[#2B5366] border-2'
                              }`}
                              onClick={() => handleDocumentCountChange('85+')}
                          >
                            powyżej 85 dokumentów
                          </div>
                        </>
                    )}
                  </div>
                </div>
                <div className="step mb-8 flex flex-col justify-center items-center">
                  <h2 className="text-xl mb-2">3. Kadry</h2>
                  <div className="mb-4 w-64 flex flex-col text-left">
                    <label className="text-gray-700 text-sm mb-2" htmlFor="staff">
                      Ilość pracowników
                    </label>
                    <input
                        className="shadow appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="staff"
                        type="number"
                        min="0"
                        step="1"
                        value={employeeCount}
                        onChange={(e) => handleEmployeeCountChange(e.target.value)}
                    />
                  </div>
                </div>
                <div className="step mb-8">
                  <div className="flex flex-row items-center justify-center">
                    <h3 className="font-bold text-xl mr-2">Cena obsługi:</h3>
                    <span className="font-semibold text-2xl text-[#325F74]">
                    {price === 'Wycena indywidualna' ? price : `${price} zł*`}
                  </span>
                  </div>
                  <div className="text-gray-500 text-sm">
                    *podana cena jest ceną orientacyjną, aby poznać dokładną cenę prosimy uzupełnić formularz
                  </div>
                </div>
                <div className="step flex flex-col items-center">
                  <h2 className="text-xl mb-2">4. Podaj dane kontaktowe</h2>
                  <div className="w-full flex flex-col md:flex-row">
                    <div className="mb-4 md:mr-2 w-full flex flex-col text-left">
                      <div className="flex flex-col md:flex-row">
                        <div className="mb-4 md:mr-2 w-full flex flex-col text-left">
                          <label className="text-gray-700 text-sm mb-2" htmlFor="phone">
                            Numer telefonu
                          </label>
                          <input
                              className="mb-2 shadow appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              id="phone"
                              type="text"
                              value={phoneNumber}
                              onChange={(e) => setPhoneNumber(e.target.value)}
                          />
                          {errors.phoneNumber && <div className="text-sm text-red-500">{errors.phoneNumber}</div>}
                        </div>
                        <div className="mb-4 md:ml-2 w-full flex flex-col text-left">
                          <label className="text-gray-700 text-sm mb-2" htmlFor="email">
                            Email
                          </label>
                          <input
                              className="mb-2 shadow appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              id="email"
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                          />
                          {errors.email && <div className="text-sm text-red-500">{errors.email}</div>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="step flex flex-col items-center">
                  <div className="mb-4 w-full flex flex-col text-left">
                    <button
                        type="submit"
                        className="flex justify-center items-center hover:bg-[#325F74] bg-white text-[#325F74] hover:text-white rounded-md border-[#2B5366] border-2 px-2 py-4 cursor-pointer"
                    >
                      {
                        submitted &&
                          <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                               fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                    strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                      }
                      {
                        !submitted &&
                          'Wyślij zapytanie'
                      }
                    </button>
                  </div>
                  <div className={'mt-2'}>
                    {lastResult && <div className="text-sm text-[#325F74]">{lastResult?.message}</div>}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
  );
};

export default App;
